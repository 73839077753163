import React from 'react'
import useWidth from 'src/hooks/window/useWidth'

import { CustomSection } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import ImageWebp from 'src/components/ImageWebp'

interface IProps {
  openModal: () => void;
}

const WIDTH_MD = 720

const UseQuantasVezes = ({ openModal }: IProps) => {
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()

  const arrayNumbers: number[] = [
    312, 336, 456, 552,
  ]

  const arrayHeight: number[] = [
    255, 275, 373, 452,
  ]

  const oneLink = 'https://inter-co.onelink.me/Qyu7/jh4wryck'

  return (
    <CustomSection id='use-quantas-vezes'>
      <div className='container py-5'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6 order-md-2 text-md-right'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/duo-gourmet-app/image.png'
              altDescription='Celular com o app do Duo Gourmet na tela e ao fundo um sanduíche grande.'
              arrayNumbers={arrayNumbers}
              arrayNumbersHeight={arrayHeight}
            />
          </div>
          <div className='col-12 col-md-6 col-xl-5 pr-0'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 text-grayscale--500 mt-4'>
              Use quantas vezes quiser em diversas cidades no Brasil e EUA
            </h2>
            <p className='fs-14 lh-17 fs-md-16 lh-md-19 fs-lg-18 lh-lg-22 text-grayscale--400 mt-2'>
              Você aproveita ao máximo e recupera seu investimento logo nas primeiras utilizações, seja nos milhares de restaurantes ou em experiências exclusivas.
            </p>
            {width > WIDTH_MD ? (
              <button
                className='btn text-white btn-orange--extra text-none mt-2'
                title='Quero assinar'
                onClick={() => {
                  openModal()
                  sendDatalayerEvent({
                    section: 'dobra_02',
                    section_name: 'Use quantas vezes quiser em diversas cidades no Brasil e EUA',
                    element_action: 'click button',
                    element_name: 'Quero assinar',
                  })
                }}
              >
                Quero assinar
              </button>
            ) : (
              <a
                href={oneLink}
                target='_blank'
                rel='noreferrer'
                title='Quero assinar'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_02',
                    section_name: 'Use quantas vezes quiser em diversas cidades no Brasil e EUA',
                    element_action: 'click button',
                    element_name: 'Quero assinar',
                    redirect_url: oneLink,
                  })
                }}
                className='btn text-white btn-orange--extra text-none mt-2'
              >
                Quero assinar
              </a>
            )}
          </div>
        </div>
      </div>
    </CustomSection>
  )
}

export default UseQuantasVezes

import React, { useState } from 'react'
import { Wrapper } from './style'
import pageContext from './pageContext.json'
import { initTheme, Theme } from '@interco/inter-ui'

import useDomReady from 'src/hooks/window/useDomReady'
import Layout from 'src/layouts/BaseLayout'

import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import { Modal } from 'src/components/Modal'

import QrCodeDuoGourmet from './assets/images/qrcode-lp-duogourmet.png'

import UseQuantasVezes from './sections/useQuantasVezes/_index'
import Vantagens from './sections/vantagens/_index'

import ComoUsar from './sections/comoUsar/_index'
import RestaurantesPerto from './sections/restaurantes-perto/_index'
import DuoLovers from './sections/duoLovers/_index'
import Faq from './sections/faq/_index'
import Hero from './sections/hero/_index'
import EscolhaSeuPlano from './sections/escolha-seu-plano/_index'
import SoliciteSeuCartao from './sections/solicite-seu-cartao/_index'

const DuoGourmet = () => {
  const [ isOpen, setIsOpen ] = useState(false)
  const domReady = useDomReady()

  React.useEffect(() => {
    initTheme(Theme.PF)
  }, [])

  const handleOpenModal = () => {
    setIsOpen(!isOpen)
  }

  const ModalDuoGourmet = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='<span class="d-block fs-24 lh-30">Acesse o <strong class="text-orange--extra">Super App</strong> para assinar o Duo Gourmet.</span>'
        subtitle='<span class="d-block fs-14 lh-17 fs-md-16 lh-md-19 text-grayscale--500">Comece a economizar pedindo 2 pratos e pagando apenas 1 em milhares de restaurantes pelo Brasil.</span>'
        instructions='Ligue a câmera do seu celular e aponte para o QR Code acima. Você será redirecionado para a loja de aplicativos. Aí é só baixar o app do Inter.'
        qrCode={QrCodeDuoGourmet}
      />
    </Modal>
  )

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {ModalDuoGourmet}
        <Hero openModal={handleOpenModal} />
        <UseQuantasVezes openModal={handleOpenModal} />
        <Vantagens openModal={handleOpenModal} />
        <EscolhaSeuPlano openModal={handleOpenModal} />
        <SoliciteSeuCartao openModal={handleOpenModal} />
        <ComoUsar />
        <RestaurantesPerto openModal={handleOpenModal} />
        <DuoLovers openModal={handleOpenModal} />
        <Faq />
      </Layout>
    </Wrapper>
  )
}

export default DuoGourmet

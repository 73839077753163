import { device } from 'src/styles/breakpoints'
import { grayscale, orange, white, black } from 'src/styles/colors'
import styled, { css } from 'styled-components'
import * as T from './_types'

const commonStyles = {
  cardParagraph: css`
    font-family: "Inter", Helvetica, sans-serif;
    text-align: center;
  `,
}

export const ChooseYourSubscription = styled.section`
  background: ${white};

  @media ${device.desktopLG} {
    button {
      max-width: 100%;
    }
  }

`

export const Title = styled.h2`
  width: 80%;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  color: ${grayscale[500]};
  text-align: left;

  @media ${device.tablet} {
    font-size: 40px;
    line-height: 50px;
    width: 100%;
    text-align: center;
  }

  @media ${device.desktopLG} {
    font-size: 32px;
    line-height: 40px;
    text-align: left;
    margin-right: -5px;
  }

  @media ${device.desktopXXL} {
    font-size: 40px;
    line-height: 50px;
  }
`

export const Description = styled.p`
  font-family: "Inter", Helvetica, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${grayscale[400]};

  margin-bottom: 24px;
  
  @media ${device.tablet} {
    font-size: 16px;
    line-height: 19px;
  }

  @media ${device.desktopLG} {
    font-size: 18px;
    line-height: 22px;
  }

  @media ${device.desktopXXL} {
    margin-bottom: 40px;
  }
`

function selectCardVariationColors ({ variant = 'black' }: T.CardAssinaturaVariants) {
  if (variant === 'black') {
    return (
      css`
        background: ${grayscale[500]};
        color: white;
        span {
          color: white;
        }
      `
    )
  }
  if (variant === 'white') {
    return (
      css`
        background: ${white};
        color: ${grayscale[500]};
        span {
          color: ${grayscale[500]};
        }
      `
    )
  }
}

export const SubscriptionCard = styled.div<T.CardAssinaturaVariants>`
  ${(props: T.CardAssinaturaVariants) => selectCardVariationColors(props)}
  
  
  padding: 30px 32px 30px 32px;
  border: 1px solid ${grayscale[100]};
  border-radius: 8px;
  box-shadow: 0px 8px 16px 2px #16161614;
  cursor: pointer;
  
  width: 100%;
  max-width: 456px;
  height: 100%;
  min-height: 100%;
  
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  position: relative;
  

  @media ${device.tablet} {
    height: 160px;
    padding: 12px 18px 22px 18px;
  }

  @media ${device.desktopLG} {
    padding: 0;
  }

  @media ${device.desktopXL} {
    height: 190px;
  }
`

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  @media ${device.tablet} {
    flex-direction: row;
    margin-bottom: 32px;
    gap: 12px;
  }

  @media ${device.desktopLG} {
    top: -15px;
    right: -16px;

    gap: 12px;
  }
`

export const Tag = styled.img`
  position: absolute;
  top: -11px;
  right: 0%;

  width: 95px;
  height: 48px;

  @media ${device.tablet} {
    top: -12px;
    right: -15px;
  }

  @media ${device.desktopLG} {
    top: -15px;
    right: -16px;
  }

  @media ${device.desktopXXL} {
    width: 90px;
    height: 46px;

    top: -14px;
    right: -19px;
  }

`

export const SubscriptionType = styled.p`
  font-family: 'Sora', Helvetica, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 8px;
`

function selectSubscriptionPriceColor ({ variant = 'orange' }: T.SubscriptionValueVariations) {
  if (variant === 'white') {
    return (
      css`
        color: ${white};
      `
    )
  }
  if (variant === 'orange') {
    return (
      css`
        color: ${orange[500]};
      `
    )
  }
}

export const SubscriptionPrice = styled.p<T.SubscriptionValueVariations>`
  ${(props: T.SubscriptionValueVariations) => selectSubscriptionPriceColor(props)}

  font-family: 'Sora', Helvetica, sans-serif;
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  margin-bottom: -8px;

  span{
    font-size: 14px;
    color: #ffffff;
  }

  @media ${device.desktopLG} {
    font-size: 24px;
  }
`

export const SubscriptionPriceParcels = styled.span`
  font-family: 'Sora', Helvetica, sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
`

export const SubscriptionDescription = styled.p`
  ${commonStyles.cardParagraph}
  font-weight: 600;
  font-size: 14px;
  margin: 6px 0 8px 0;

  @media ${device.tablet} {
    font-size: 14px;
    line-height: 17px;
  }

  @media ${device.desktopLG} {
    font-size: 10px;
  }

  @media ${device.desktopXL} {
    font-size: 14px;
  }
`

export const Details = styled.p`
  ${commonStyles.cardParagraph}
  font-weight: 400;
  font-size: 10px;
  line-height: 9px;
  color: ${black};
  position: relative;
  top: 8px;

  @media ${device.desktopLG} {
    line-height: 17px;
    top: 5px;
  }
`

export const PromotionalCoupon = styled.span`
  ${commonStyles.cardParagraph}
  display: inline;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
`

export const PromotionValidDate = styled.span`
  ${commonStyles.cardParagraph}

  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  margin: 0;
`

export const TagPromotion = styled.p`
  margin: 0;
  font-size: 12px;
  background-color: #00AA4F;
  color: #fff;
  padding: 5px 9px 5px 9px;
  border-radius: 20px;
  font-weight: bold;
  line-height: 15px;
  height: 25px;
  letter-spacing: -1px;
  text-align: center;
  font-family: "Inter", Helvetica, sans-serif;
  margin: 0 auto;  
  margin-top: -26px;
  display: flex;
  justify-content: center;

  @media ${device.desktopLG} {
    margin-top: -64px;
  }

  @media ${device.desktopXXL} {
    right: -10px;
    top: -58px;
  }
  `

export const TextWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 12px;

  p {
    margin: 0;
  }

  &.black{
    p {
      color: #ffffff;
    }
  }
  &.white{
    p {
      color: #161616;
    }
  }

  .text-title{
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0px;
  }
  
  .content-price{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;

    .text-parcel{
      font-weight: 400;
      font-size: 12px;
      line-height: 100%;
      letter-spacing: 0px;
    }

    .text-price{
      font-weight: 600;
      font-size: 24px;
      line-height: 100%;
      letter-spacing: 0px;
      color: #EA7100;
      
      span{
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        letter-spacing: 0px;
        color: #EA7100;
      }
    }
  }

  .text-info-card{
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0px;
  }

  @media ${device.desktopLG} {
    height: auto;
    padding: 0;
    width: 168px;
    position: relative;
    margin: 0 auto;
  }

  @media ${device.desktopXL} {
    height: 120px;
    top: 12px;
  }
`

import React from 'react'
import useWidth from 'src/hooks/window/useWidth'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { WIDTH_MD } from 'src/styles/breakpoints'
import * as S from './style'
import { Button } from '@interco/inter-ui/components/Button'

interface IProps {
  openModal: () => void;
}

const Hero = ({ openModal }: IProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()

  const oneLink = 'https://inter-co.onelink.me/Qyu7/jh4wryck'

  return (
    <S.HeroContainer
      className='justify-content-md-center d-md-flex bg-black'
      role='img'
      aria-label='Mão esquerda segurando um celular com o app do Duo Gourmet na tela e mão direita mostrando um cartão Black com um prato servido à mesa ao fundo.'
    >
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6 col-lg-5 pb-md-0 offset-md-6 offset-lg-7 px-0'>
            <S.Title>
              Assine o plano anual do Duo Gourmet e ganhe um Cartão Black
            </S.Title>
            <S.Description>
              Com o Duo Gourmet <strong>você pede 2 pratos e paga apenas 1</strong>. Assine e comece a economizar nos <strong>melhores restaurantes</strong> do Brasil!
            </S.Description>
            {width > WIDTH_MD ? (
              <Button
                fullWidth
                title='Assine e garanta seu desconto'
                onClick={() => {
                  openModal()
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    section_name: 'Assine o plano anual do Duo Gourmet e ganhe um Cartão Black',
                    element_action: 'click button',
                    element_name: 'Assine e garanta seu desconto',
                   })
                }}
              >
                Assinar agora
              </Button>
            ) : (
              <Button
                fullWidth
                as='a'
                href={oneLink}
                className='mt-3'
                title='Assinar agora'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    section_name: 'Assine o plano anual do Duo Gourmet e ganhe um Cartão Black',
                    element_action: 'click button',
                    element_name: 'Assinar agora',
                    redirect_url: oneLink,
                   })
                }}
              >
                Assinar agora
              </Button>
            )}
          </div>
        </div>
      </div>
    </S.HeroContainer>
  )
}

export default Hero

import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const HeroContainer = styled.section`
  background: ${grayscale[500]};
  padding: 60px 0;

  @media ${device.tablet} {
    background: ${grayscale[500]} url('https://central-imagens.bancointer.com.br/images-without-small-versions/duo-week-consumidor-25/image.png') no-repeat;
    background-position: center center;
    background-size: cover;
    align-items: center;
  }

  @media ${device.desktopLG} {
    padding: 96px 0;
  }

  @media ${device.desktopXXL} {
    background-position: center top;
    min-height: 36vw;
  }
`

export const PromotionValidDate = styled.span`
  display: block;
  margin-top: 16px;

  font-family: "Inter", Helvetica, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  color: ${grayscale[200]};

  @media ${device.desktopLG} {
    margin-top: 24px;
  }
`

export const Title = styled.h1`
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: white;
  margin-bottom: 16px;
  
  @media ${device.tablet} {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 16px;
    width: 332px;
  }

  @media ${device.desktopXL} {
    width: 414px;
  }

  @media ${device.desktopXXL} {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 16px;
  }
`

export const Description = styled.p`
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 32px;
  color: white;

  @media ${device.tablet} {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 40px;
  }

  @media ${device.desktopXXL} {
    font-size: 18px;
    line-height: 22px;
  }
`

export const LegalText = styled.p`
  font-size: 14px;
  line-height: 17px;
  color: white;
`
